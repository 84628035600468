// @flow
/* global googletag window */

import * as React from 'react';
import { useFela, } from 'react-fela';
import dynamic from 'next/dynamic';

import type { StyleProps, } from '@haaretz/htz-css-tools';
import { useQuery, } from 'react-apollo';
import Zen from '../Zen/Zen';
import StaticAdSlot from './StaticAdSlot';
import { OutOfPageFormat, } from './OutOfPageAdSlot';
import { useUser, } from '../User/UserDispenser';
import usePreview from '../../hooks/Page/usePreview';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useMedia from '../../hooks/useMedia';
import { inlineStyle2Obj, } from './utils';
import useDocumentEventListener from '../../hooks/useDocumentEventListener';

import PAGE_TYPE_CLIENT_QUERY from './pageTypeClientQuery.graphql';

import type { AdSlotType, } from '../../flowTypes/AdSlotType';

const OutOfPageAdSlot = dynamic(import('./OutOfPageAdSlot'));

export type AdSlotProps = {
  wrapperMiscStyles?: ?StyleProps,
  /* This event is fired when the creative's iframe fires its load event */
  slotOnLoad?: (slot) => void;
  /* This event is fired when an impression becomes viewable */
  slotImpressionViewable?: (slot) => void;
} & AdSlotType;

// styles
const miscCssClasses = {
  'top-margin': {
    paddingTop: '5rem',
  },
  'bottom-margin': {
    paddingBottom: '5rem',
  },
  'line-start-margin': {
    paddingInlineStart: '5rem',
  },
  'line-end-margin': {
    paddingInlineEnd: '5rem',
  },
};

function baseStyle() {
  return {
    overflowX: 'hidden',
    maxWidth: '100%',
    '&:has(> :empty)': {
      display: 'none',
    },
  };
}

// test targeted user-type
function shouldTargetUser(user, targetUserType) {
  if (!user?.anonymousId) {
    return false;
  }

  let shouldTargetUser = false;

  if (targetUserType === 'all') {
    shouldTargetUser = true;
  }
  else {
    const userType = user?.type || 'anonymous';

    switch (targetUserType) {
      case 'nonPaying':
      case 'anonymous':
      case 'registered':
        shouldTargetUser = [ 'anonymous', 'registered', ].includes(userType);
        break;
      case 'paying':
      case 'digitalOnly':
      case 'digitalAndPrint':
        shouldTargetUser = userType === 'paying';
        break;
      default:
        shouldTargetUser = true;
    }
  }

  return shouldTargetUser;
}

// discovers the user platform (app | mobile | desktop)
function usePlatform() {
  const isApp = useWebViewChecker();
  const isMobile = useMedia({ query: { until: 's', }, matchOnServer: true, });

  let platform = null;

  if (isApp) {
    platform = 'app';
  }
  else if (!isApp && isMobile) {
    platform = 'mobile';
  }
  else {
    platform = 'desktop';
  }

  return platform;
}

function usePageType() {
  const { data, error, } = useQuery(PAGE_TYPE_CLIENT_QUERY);

  if (error) {
    console.error(`Error in usePageType: ${error.message}`);
    return null;
  }

  const { pageType, articleType, } = data || {};

  let result: string;

  if (articleType) {
    result = articleType;
  }
  else {
    result = pageType;
  }

  result = result?.replace(/^[a-z]/, match => match.toUpperCase());

  return result;
}

export default function AdSlot({ interstitial, targetUsers, excludePlatforms, excludePageTypes, inlineStyle, cssClass, wrapperMiscStyles = null, ...props }: AdSlotProps) {
  const { css, } = useFela();
  let divId = React.useId();
  const { user, } = useUser();
  const { isPreview, } = usePreview();
  const platform = usePlatform();
  const pageType = usePageType();
  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = React.useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);

  const shouldExcludeFromPageType = !!excludePageTypes && pageType && excludePageTypes.includes(pageType);
  const shouldExcludeFromPlatform = !!excludePlatforms && platform && excludePlatforms.includes(platform);

  const onLoadElement = React.useCallback(() => {
    setIsLoaded(true);
  }, []);

  useDocumentEventListener('loadElement', onLoadElement, false);

  // eslint-disable-next-line operator-linebreak
  const shouldDisplay =
  // if is mobile-app and 'loadElement' event was fired by the application
  (!isWebView || isLoaded)
  // if the ad-slot is not excluded from the page-type
  && !shouldExcludeFromPageType
  // if the ad-slot is not excluded from the platfrom (mobile | desktop | app)
  && !shouldExcludeFromPlatform
  // if page is in preview-mode  or current user is targeted for this ad-slot
  && (isPreview || shouldTargetUser(user, targetUsers));

  // Calculate inine style object
  const styleObj = React.useMemo(() => {
    let cssObj = null;

    if (inlineStyle && inlineStyle.length > 0) {
      cssObj = Object.assign(cssObj || {}, inlineStyle2Obj(inlineStyle));
    }

    if (cssClass && cssClass.length > 0) {
      cssClass.split(' ').forEach(className => {
        cssObj = Object.assign(cssObj || {}, miscCssClasses[className] || {});
      });
    }

    return cssObj;
  }, [ inlineStyle, cssClass, ]);

  if (!props.adUnitPath) {
    console.warn(`An attempt to render an Ad-Slot with no 'adUnitPath' with props: ${JSON.stringify({ interstitial, targetUsers, wrapperMiscStyles, ...props, })}`);
    return null;
  }

  if (!shouldDisplay) {
    return null;
  }

  if (interstitial) {
    divId = OutOfPageFormat.INTERSTITIAL;
  }

  const styles = Object.assign({}, wrapperMiscStyles || {}, styleObj || {});

  return (
    <Zen miscStyles={styles} className={css(baseStyle)} animate>
      {interstitial
        ? <OutOfPageAdSlot divId={divId} isPreview={isPreview} {...props} />
        : <StaticAdSlot divId={divId} isPreview={isPreview} {...props} />}
    </Zen>
  );
}
